<template lang="html">
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md3>
        <v-form ref="form" @submit.prevent="onLogin" @keyup.native.enter="onLogin">
          <v-card class="elevation-12">
            <v-toolbar dark color="#0f2e8c">
              <v-toolbar-title>ĐĂNG NHẬP VÀO TOMEXPRESS PORTAL</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon large>mdi-login</v-icon>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field prepend-icon="mdi-email" label="Tài khoản" type="text" v-model="login.login" required></v-text-field>
                <v-text-field prepend-icon="mdi-lock" label="Password" id="password" type="password" v-model="login.password" required></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#0f2e8c" :loading=loadingStatus :v-show=disableStatus type="submit" dark>{{loginText}}</v-btn>
            </v-card-actions>
          </v-card>
      </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  computed: {
        ...mapGetters(['authStatus','authError']),
        disableStatus(){
            if(this.authStatus === "error" || this.authStatus === "") return false;
            return true;
        },
        loadingStatus(){
            if(this.authStatus === "loading") return true;
            return false;
        },
    },  
  data: () => ({
    formValid: false,
    login: {
      login: '',
      password: ''
    },
    loginText: "Đăng nhập"
  }),
  watch:{
    authStatus:function(val){
        if(val === "success"){
            this.loginText = "Đã đăng nhập";
        }
    },
    authError:function(val){
        this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: val,
            showConfirmButton: false,
            timer: 5000
        });
    }
  },
  methods: {
    onLogin () {
      this.$store.dispatch('AUTH_REQUEST', this.login).then(resp=> {
        if(resp.data.token){
          window.location.href='/'
          // this.$router.push('/')
        }
      }).catch(error=>{
        this.$store.commit('AUTH_ERROR',error);
      });
    }
  }
}
</script>

<style lang="css" scoped>
</style>